.login_bg{
    background-size: cover;
    background: url(../../Static/Images/logbg.png);
    width: 100%;
    height: calc(100vh - 80px);
    background-size: cover;
    background-position: center;
    
    img{
        width: 100%;
        height: 100vh;
    }
}
.user--type{
    width: 350px;
    height: 265px;
    padding: 30px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: rgb(246 246 246);
    border: 1px solid rgb(229, 229, 229);
    border-radius: 8px;
    text-align: center;
    font-family: Roboto;
    //filter: drop-shadow(0px 4px 10px rgba(44, 63, 88, 0.15));
    h3{
        margin-bottom: 50px;
        font-family: Roboto;
    }
    button{
        margin-top: 20px;
        text-align: center;
        display: block;
        width: 100%;
        background-color: #02549f;
        font-family: Roboto;
        font-style: normal;
        font-size: 16px;
        line-height: 16px;
        height: 40px;
    }
    .form-select{
        font-family: Roboto;
        font-style: normal;
        font-size: 16px;
        line-height: 16px;
        height: 40px;
        &:focus{box-shadow: none;}
    }
}