

//Grapgh Map css
.graph--map {
    max-height: 900px;
    overflow: hidden;
    svg{
        background: #E2F1FF;
        fill: #fff;
        
        a{
            text-decoration: none;
        }
    }
}