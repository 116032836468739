/* Roboto Fonts */
@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,300;0,400;0,500;0,700;0,900;1,400;1,500;1,700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

body{padding-bottom: 60px; position: relative; height: 100vh;}

.btn-check:active+.btn-primary, .btn-check:checked+.btn-primary, .btn-primary.active, .btn-primary:active, .show>.btn-primary.dropdown-toggle {
    color: rgba(0, 0, 0, 0.54) !important;
    background-color: #fff !important;       
    border-color: #ced4da !important;
    outline: none;
    box-shadow: none;
}
.btn-check:active+.btn-primary:focus, .btn-check:checked+.btn-primary:focus, .btn-primary.active:focus, .btn-primary:active:focus, .show>.btn-primary.dropdown-toggle:focus {
    box-shadow: none !important;
}
button:focus:not(:focus-visible) {
    outline: none;
    box-shadow: none;
}





  
  