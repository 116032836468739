
// neww table
.table--main {
    .react-bootstrap-table{

        tbody, td, tfoot, th, thead, tr{
            border: none;
            border-bottom: 1px solid #E8E9EB;
        }    
    }
}
.table--main .react-bootstrap-table th{
    color: #8A9099;
    font-family: Poppins;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 21px;
    text-transform: uppercase;
    border-color: #E8E9EB;
}
.table--main .react-bootstrap-table td{
    font-family: Poppins;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    line-height: 22px;
    color: #3F434A;
    vertical-align: middle;
    border-bottom: 1px solid #E8E9EB;
}

.table--main .export_btn{
    background: #fff;
    color: #3F434A;
    font-family: Poppins;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 21px;
    border-width: 1px;
    margin-right: 30px;
    border-radius: 14px;
    position: absolute;
    left: 60%;
    bottom: 18px;
    padding: 6px 12px;
    

    @media screen and (max-width: 991px) {
        position: static;
    }
}
.table--main .export_btn:hover,
.table--main .export_btn:hover svg{
    background-color: #304FFD;
    color: #fff;
    fill: #fff;
    border-color: #304FFD;
}

.table--main .row.react-bootstrap-table-pagination {
    
    .page-item a{        
            border-radius: 14px;
            font-family: Poppins;
            font-style: normal;
            font-weight: normal;
            font-size: 15px;
            line-height: 22px;
            color: #3F434A;
            border: none;
            padding: 6px 14px;
            cursor: pointer;  
            display: flex;
            justify-content: center;
            border-bottom-left-radius: 14px !important;
            border-top-left-radius: 14px !important;
            border-top-right-radius: 14px !important;
            border-bottom-right-radius: 14px !important;  
    }
}
.table--main .row.react-bootstrap-table-pagination .active.page-item a{color: #fff; background-color: #304FFD !important;}
.table--main .row.react-bootstrap-table-pagination .page-item{ margin-right: 6px; width: 34px;}
@media screen and (max-width:991px){
    .table--main.row.react-bootstrap-table-pagination>div:first-child{width: 30%;}
}
.table--main{
    position: relative;

    table.table.table-bordered {
        border-collapse: initial;
        overflow-x: auto;
        display: block;
    }
    .react-bootstrap-table-pagination-list {
        padding: 0;

        @media screen and (max-width: 991px) {
            width: 70%;
        }

        @media screen and (max-width: 991px) {
            .react-bootstrap-table-page-btns-ul{margin-bottom: 0;}
        }
    }
    .react-bootstrap-table-pagination-list ul li:nth-child(1) a,
    .react-bootstrap-table-pagination-list ul li:nth-child(7) a{
        padding: 6px 11px;
    }
    .react-bootstrap-table-pagination-list ul li:nth-child(1) a,
    .react-bootstrap-table-pagination-list ul li:nth-child(2) a{
        background-color: #F8F8F8; letter-spacing: -3px; 
    }
    .react-bootstrap-table-pagination-list ul li:nth-child(6) a,
    .react-bootstrap-table-pagination-list ul li:nth-child(7) a{
        background-color: #D8D8D8; color: #304FFD; letter-spacing: -3px; 
    }
    span.react-bootstrap-table-pagination-total {
        color: #8A9099;
        font-family: Poppins;
        font-style: normal;
        font-weight: normal;
        font-size: 15px;
        line-height: 22px;

        @media screen and (max-width: 991px) {
            display: none;
        }
    }
    button#pageDropDown {
        background: #fff;
        color: #3F434A;
        border-color: #E8E9EB;
        padding: 10px 16px;
        height: 40px;
        margin-right: 14px;
        line-height: 10px;
        font-size: 14px;
        border-radius: 14px;
    
        &:focus{box-shadow: none;}
    }
    input.selection-input-4{
        width: 16px;
        height: 16px;
        border-color: #E8E9EB;
    }
    .light_text{
        font-family: Poppins;
        font-style: normal;
        font-weight: normal;
        font-size: 15px;
        line-height: 22px;
        color: #8A9099;
        vertical-align: middle;
    }
    .bold_text{       
        color: #333 !important;
        font-weight: 600 !important;
    }
    // .react-bootstrap-table th:nth-child(2) {
    //     width: 120px;
    // }
    // .react-bootstrap-table th:nth-child(3) {
    //     width: 550px;
    // }
    // .react-bootstrap-table th:nth-child(4) {
    //     width: 330px;
    // }
    // .react-bootstrap-table th:nth-child(5) {
    //     width: 210px;
    // }
    // .react-bootstrap-table th:nth-child(6),
    // .react-bootstrap-table th:nth-child(7),
    // .react-bootstrap-table th:nth-child(8) {
    //     width: 120px;
    // }
    .item_img{
        display: flex; 
        align-items: center;
        vertical-align: middle;
        img{margin-right: 10px;}
        p{margin-bottom: 0;}
        
        img{width: 35px;}
        span{color: #3F434A;}
    }
    .item_name{
        font-family: Poppins;
        font-style: normal;
        font-weight: normal;
        font-size: 15px;
        line-height: 22px;
        color: #3F434A;

        span{
            color: #565a63;
            font-size: 10px;
            line-height: 15px;
            font-weight: 500;
        }
    }
    .item_name1{
        font-weight: 600;
    }
}
.search-label{
    position: absolute;
    top: -70px;
    width: 27.5%;

    input{
        height: 40px;        
        font-family: Roboto;
            font-style: normal;
            font-weight: 400;
            font-size: 16px;
            line-height: 16px;
            color: rgba(0, 0, 0, 0.87);
            border: 1px solid rgba(0, 0, 0, 0.38);
        &::placeholder{
            font-family: Roboto;
            font-style: normal;
            font-weight: 400;
            font-size: 16px;
            line-height: 16px;
            color: rgba(0, 0, 0, 0.87);
        }
        &:focus{box-shadow: none;}
    }
        @media screen and (max-width: 991px) {
            position: static;
            width: 100%;
        }
    @media screen and (min-width: 1200px) and (max-width: 1400px) {
        width: 26%;
    }   
    @media screen and (min-width: 992px) and (max-width: 1199px) {
        width: 23.69%;
      }
}
.total_cal{
    font-family: Poppins;
    font-style: normal;
    font-weight: 400;
    color: #3F434A;
    font-size: 18px;
    line-height: 24px;
    text-transform: capitalize;
    text-align: right;
    display: flex;    
    justify-content: space-between;
    margin-bottom: 20px;
    a{
        color: #3F434A;
        text-decoration: none;
    }
    .total_val{margin-right: 20px; display: inline-block; }
    .total_val1{display: inline-block; }
    p{margin-bottom: 0;}
    span{ font-weight: 600;}
}
.action_btn{margin: 0;}
.item_alert{position: relative;}
.item_alert .modal-offtext{position: absolute; left: 50%; top: -33px; color: #fff; transform: translate(-50%, 0%); font-family: Roboto;}
.item_alert .modal-header{background-color: orange !important;}
.item_alert .modal-title{color: #fff !important;}
.item_alert .modal-body .row{align-items: center !important;}
.item_alert .btn-modal{       
    color: #fff;
    padding: 9px 16px;
    font-family: Roboto;
    font-style: normal;
    border-radius: 14px;
    font-weight: 500;
    font-size: 16px;
    line-height: 18px;
    padding: 9px 110px;
    margin-top: 55px;

    &:disabled{
        background-color: #D8D8D8;
        border: none;
    }
}
.item_alert .processing{
    position: absolute;
    right: 20px;
    bottom: 10px;
    font-family: Roboto;
    font-size: 12px;
    color: #000;
    text-decoration: none;
    border: 1px solid #e6e6e6;
    padding: 5px 10px;
    border-radius: 14px;

    &:hover{
        background-color: #304FFD;
        color: #fff;
    }
}

