// Header css
.headerout{    
    .container{
        justify-content: center !important;
    }
}

.headerout .LogoResponsive1{margin-right: 0;}
.header-container{
    box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.25);
    background: #F9F6F6;
    min-height: 80px;
    .navbar-brand{
        padding: 0; 
        max-width: 200px;
        img{
            width: 100%;
        }
    }
    .notification_bell{
        position: relative;
        margin-right: 20px;
        cursor: pointer;
        .fa-bell{
            font-size: 30px;      
            color: #183C73;                  
        }
        .badge{
            position: absolute;
            top: -10px;
            left: 10px;
            background-color: #F44336 !important;
            border-radius: 50px;
            font-size: 13px;
            line-height: 12px;
            font-family: Roboto;
            font-style: normal;
            font-weight: normal;
        }
        .notification_box{
            filter: drop-shadow(0px 24px 40px rgba(44, 63, 88, 15%));
            background: #fff;
            border:none;
            border-radius: 14px;
            min-width: 340px;
            box-sizing: border-box;
            position: absolute;
            top: 40px;
            right: -10px;
            z-index: 99;
            .Headerr{  
                display: flex;
                align-items: center; 
                justify-content: space-between;
                padding: 20px 24px;
                p{
                    margin: 0;                
                    font-family: Poppins;
                    font-style: normal;
                    font-weight: 500;
                    font-size: 18px;
                    line-height: 27px;
                    color: #3F434A;
                    display: inline-block;
                }
                .badge{                   
                    background-color: #F44336 !important;
                    border-radius: 50px;
                    font-weight: 600;
                    font-size: 10px;
                    line-height: 15px;
                    font-family: Roboto;
                    font-style: normal;
                    position: static;                   
                    opacity: 0.7;
                }
            }
            .contentt{                
                border-top: 1px solid #E8E9EB; 
                padding: 20px 24px;
                
                .notification_item{
                    display: flex;
                    align-items: flex-start;
                    margin-bottom: 10px;
                    .avtar1{
                        width: 58px;
                        height: 58px;
                        border-radius: 50px;
                        overflow: hidden;
                    }
                }
                .item_details{           
                    font-family: Poppins; 
                    font-style: normal;   
                    margin-left: 10px;
                    p{margin-bottom: 0;}
                       
                    .itemname{        
                        font-weight: 500;
                        font-size: 15px;
                        line-height: 22px;
                        color: #3F434A;
                    }
                    .itemstatus{ 
                        font-weight: normal;
                        font-size: 13px;
                        line-height: 19px;
                        color: rgba(63, 67, 74, 0.7);
                    }
                    .timestamp{
                        font-weight: normal;
                        font-size: 12px;
                        line-height: 18px;
                        color: #8A9099;
                    }
                }
            }

        }
    }
        .avtar_img{
            width: 40px;
            height: 40px;
            border-radius: 50px;
            background-color: #02549F;
            color: #fff;
            display: flex;
            justify-content: center;
            align-items: center;
            margin-right: 10px;
            p{
                margin: 0;
                font-size: 16px;
                font-family: Roboto;
                font-style: normal;
                font-weight: normal;
                color: #02549f;

                font-size: 0;
                :first-letter {
                    opacity: 1;
                    font-size: 26px;
                    color: #fff;
                    display: block !important;
                }
                :not(:first-letter) {
                    opacity: 0;
                }
            }
            p:first-letter {
                opacity: 1;
                font-size: 26px;
                color: #fff;
                display: block !important;
            }
        }
        // .avtar_img p:first-letter {

        // }
        .user_text{
            position: relative;
            cursor: pointer;
            p{
                margin: 0;
                font-family: Roboto;
                font-style: normal;
                font-weight: 300;
                font-size: 16px;
                line-height: 19px;
            }
            .user_name{font-weight: 400;}
        }
    }
    .menu_box{
        filter: drop-shadow(0px 24px 40px rgba(44, 63, 88, 15%));
        background: #fff;
        border:none;
        border-radius: 14px;        
       // min-width: 340px;
        box-sizing: border-box;
        position: absolute;
        top: 40px;
        right: 0;
        z-index: 99;
        
            a{
                margin: 0;                
                font-family: Poppins;
                font-style: normal;
                font-weight: 500;
                font-size: 15px;
                line-height: 21px;
                color: #3F434A;
                display: inline-block;
                text-decoration: none;
                padding: 15px;
                min-width: 150px;
                svg{margin-right: 8px;}
                &:hover{color: #3F434A; background: #F8F8F8; border-radius: 14px;}
            }
            
        
    }

//Footer css
.footer-container{
    background: #f6f6f6;
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    text-align: center;
    padding: 12px 24px !important;

    p{
        margin: 0;
        font-family: Roboto;
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 19px;
    }
}
//Search bar css
.form-control{
    &:focus, &:active, &:hover{
        box-shadow: none;                            
    }
}
.me-0{margin-right: 0 !important;}
.search-container{
    margin-top: 45px;
    margin-bottom: 30px;

    .location_box button[disabled] {
     background-color: #F3F3F3 !important;
     color: rgba(0, 0, 0, 0.67);
    }
    .one input[disabled] {
         background-color: #F3F3F3 !important;
        color: rgba(0, 0, 0, 0.67);
    }
    .one input[disabled]::placeholder {
         background-color: #F3F3F3 !important;
        color: rgba(0, 0, 0, 0.67);
    }
    .search-container .location_box input[disabled] {
        background-color: #F3F3F3 !important;
        color: rgba(0, 0, 0, 0.67);
    }
    .search-container .one input[disabled] {
        background-color: #F3F3F3 !important;
        color: rgba(0, 0, 0, 0.67);
    }
    
     .location_box input.custom_input.form-control[disabled] {
        background-color: #F3F3F3 !important;
        color: rgba(0, 0, 0, 0.67);
    }

    .btn-notify-width {
        min-width: 200px;
    }
   
    .search_from{ 
        justify-content: flex-end;
    
        @media screen and (max-width:991px) {
            display: block !important;
        }
    }
    .search_box{
        width: 27%;

        input{
            &:focus{box-shadow: none;}
        }

        @media screen and (max-width: 991px) {
            width: 100%;
            margin-bottom: 20px;
        }
    }
    .one{        
        position: relative;
        margin-right: 10px;
        font-family: Poppins;
        label{
            position: absolute;
            top: -7px;
            left: 10px;
            background: #fff;
            color: rgba(0, 0, 0, 0.54);
            font-family: Roboto;
            font-style: normal;
            font-weight: 500;
            font-size: 12px;
            line-height: 16px;
            padding: 0 5px;
            z-index: 9;
        }  
        input{
            height:40px;
            border: 1px solid rgba(0, 0, 0, 0.38);
            font-family: Poppins;
            font-size: 15px;
            color: rgba(0, 0, 0, 0.87);
        &::placeholder{
            font-family: Roboto;
            font-style: normal;
            font-weight: normal;
            font-size: 16px;
            line-height: 24px;
            color: rgba(0, 0, 0, 0.87);
        }     
        &:focus{box-shadow: none;}   
    }
        .fa-magnifying-glass{
            font-size: 20px;  
            color: #8A9099;  
            position: absolute;
            top: calc(50% - 20px/2);
            right: 10px;
        }     
    }
    .location_box {
        width: 19%;

        @media screen and (max-width: 991px) {
            width: 100%;
            margin-bottom: 20px;
        }

        .dropdown-toggle:after { content: none }
        button{       
            height:40px;
            width: 100%;
            background: #fff !important;
            color: rgba(0, 0, 0, 0.87);
            border: 1px solid rgba(0, 0, 0, 0.38);
            text-align: left;
            font-family: Roboto;
            font-style: normal;
            font-size: 16px;
            line-height: 18px;
            white-space: initial;
            overflow: hidden;
        }
        .dropdown-menu {
            padding: 25px;
            filter: drop-shadow(0px 24px 40px rgba(44, 63, 88, 15%));
            border:none;
            border-radius: 8px;
            min-width: 310px;
            box-sizing: border-box;
            transform: translate3d(-73px, 42.4px, 0px) !important;
        }
        .dropdown-item-text{
            font-family: Poppins;
            font-style: normal;
            font-weight: 500;
            font-size: 22px;
            line-height: 33px;
            color: #3F434A;
            padding: 0;    
            margin-bottom: 8px;       
        }
        .dropdown-item{
            padding: 0;
            
            &:hover, &:active{
                background-color: #fff;
            }
        }
        .form-select{ 
            padding: 0.23rem .75rem;
            font-family: Roboto;
            font-style: normal;
            font-weight: normal;
            font-size: 16px;
            line-height: 29px;
            color: rgba(0, 0, 0, 0.87);
            border-color: rgba(0, 0, 0, 0.38);
            appearance: none;

            &:focus{box-shadow: none;}
            &:disabled {
                background-color: #F3F3F3;
                color: rgba(0, 0, 0, 0.67);
            }
        }
        .form-check{
            margin-bottom: 0px; 
            .form-check-input{height: 1em; margin-top: 10px; cursor: pointer;}
            .form-check-label{
                position: static;
                margin-left: 10px;
                font-family: Poppins;
                font-style: normal;
                font-weight: 500 !important;
                font-size: 15px !important;
                line-height: 38px !important;
                color: #595F69;
            }
        }
        .drop_btn{
            height:40px;
            width: auto;
            background-color: #304FFD !important;
            color: #fff !important;
            padding: 8px 24px;
            border-radius: 14px !important;
            font-family: Poppins;
            font-style: normal;
            font-weight: 500;
            font-size: 15px;
            line-height: 22px;
            display: flex;
            align-items: center;
            text-align: right;
            margin-left: auto;
        }
    }
    .period_box{
        width: 12% !important;

        &:focus, &:active, &:hover{
            box-shadow: none;                            
        }
        @media screen and (max-width: 991px) {
            width: 100% !important;
            margin-bottom: 20px;
        }
        .dropdown-toggle::after{float: right; margin-top: 5px;}
        .dropdown-menu {
            padding: 15px;
            filter: drop-shadow(0px 24px 40px rgba(44, 63, 88, 15%));
            border:none;
            border-radius: 8px;
            min-width: 190px;
            box-sizing: border-box;
            transform: translate3d(-40px, 42.4px, 0px) !important;
            
        }
        button{       
            height:40px;
            width: 100%;
            background: #fff !important;
            color: rgba(0, 0, 0, 0.87);
            border: 1px solid rgba(0, 0, 0, 0.38);
            text-align: left;
            font-family: Roboto;
            font-style: normal;
            font-size: 16px;
            line-height: 16px;
        }

        .dropdown-item{
            font-family: Poppins;
            font-style: normal;
            font-weight: normal;
            font-size: 15px;
            line-height: 22px;
            color: #595F69;
            padding: 0;    
            margin-bottom: 0px;  
            padding: 10px;  
            cursor: pointer;

            &:last-child{padding: 0;}
            
            &:hover{
                background:#F8F8F8;
                border-radius: 8px;
                color: #000;
            }
        }
        .drop_btn{           
            height: 40px;
            width: 100%;
            text-align: center;
            background-color: #304FFD !important;
            color: #fff !important;
            padding: 8px 24px;
            border-radius: 14px !important;
            font-family: Poppins;
            font-style: normal;
            font-weight: 500;
            font-size: 15px;
            line-height: 22px;
            display: flex;
            align-items: center;
            justify-content: center;
        }
        .custom_input{
            margin-top: 10px;
            margin-bottom: 10px;
            border-radius: 8px;
            display: flex;
            align-items: center;
            border-color: #E8E9EB;
            font-size: 12px;
            line-height: 20px;
            padding: 10px;

            &::placeholder{
                font-family: Poppins;
                font-style: normal;
                font-weight: normal;
                font-size: 12px;
                line-height: 20px;
                color: rgba(63, 67, 74, 0.4);                
            }
            &:focus, &:active, &:hover{
                box-shadow: none;                            
            }
        }
    }

    .srch_btn{
        background-color: #183C73;
        padding: 0px 18px;
        font-family: Roboto;
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 18px;
        margin-right: 10px;
        
        a{
            color: #fff;
            text-decoration: none;
            z-index: 9;
        }

        &:focus, &:active, &:hover{
            box-shadow: none;
            background-color: #10294e !important;
            color: #fff !important;
            
        }

        @media screen and (max-width: 991px) {
            padding: 9px 18px;
        }
    }

    .store_box {
        width: 19%;

        @media screen and (max-width: 991px) {
            width: 100%;
            margin-bottom: 20px;
        }

        button{       
            height:40px;
            width: 100%;
            background: #fff !important;
            color: rgba(0, 0, 0, 0.87);
            border: 1px solid rgba(0, 0, 0, 0.38);
            text-align: left;
            font-family: Roboto;
            font-style: normal;
            font-size: 16px;
            line-height: 16px;
        }

        .dropdown-toggle:after { content: none }
        
        .dropdown-menu {
            padding: 25px;
            filter: drop-shadow(0px 24px 40px rgba(44, 63, 88, 15%));
            border:none;
            border-radius: 8px;
            min-width: 189px;
            box-sizing: border-box;            
        }
       
        .dropdown-item{
            padding: 5px ;
            &:hover{
                background: #F8F8F8;
                border-radius: 8px;
                color: #000;
                padding: 5px 5px;
            }
        }             
       
    }  
    .rbt-menu > .dropdown-item{
        font-family: Poppins;
        font-style: normal;
        font-weight: normal;
        font-size: 15px;
        line-height: 22px;
        color: #595F69;
        padding: 0;
        margin-bottom: 0px;
        padding: 10px;
        cursor: pointer;

        &:hover{
            background: #F8F8F8;
            border-radius: 8px;
            color: #000;
            padding: 10px;
        }
    } 
}


//MapData css
.st0{fill:#FF9800;}
.st2{fill:#8037df;}
.st1{fill:#00D02E;}
.st3{fill:#00D0A8;}

.paths{
    display: inline;
    position: relative;

    .labels {
        fill: #fff;
        font-family: Roboto;
        font-style: normal;
        font-size: 12px;
        line-height: 16px;
        font-weight: normal;
        z-index: 1;
        display: block;
    }

    .rect_box{display: none; opacity: 0.8;}
    .label{ display: none; font-size: 8px; }

    &:hover .label, &:hover .rect_box{
        display: block; transition: all 0.2s ease;
    }
}


//Config css
.config--container{
    margin: 0 auto;
    margin-top: 60px !important;
    max-width: 900px;   
    background: rgb(246 246 246);
    border: 1px solid rgb(229, 229, 229);
    border-radius: 8px;
    text-align: center;
    font-family: Roboto;
    padding: 30px;
    position: relative;
    h3{
        margin-bottom: 50px;
        font-family: Roboto;
    }
    p{position: absolute; bottom: 8px; font-size: 12px; color: green; margin: 8px 0 0 0; text-align: left;}
    input{height: 40px; font-size: 15px; width: 80%;}
    button{       
        text-align: center;
        display: block;
        width: auto;
        float: right;
        background-color: #02549f;
        height: 40px;
        line-height: 16px;
        font-size: 16px;
        padding: 12px 24px;
       
        &:focus, &:active{box-shadow: none; background-color: #02549f !important; color: #fff !important;}
    }
    .form-select{
        &:focus{box-shadow: none;}
    }
}

