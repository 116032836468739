.item-page{
    padding-bottom: 60px;   
    position: relative;

    h2{
        font-family: Poppins;
        font-style: normal;
        font-weight: 500;
        font-size: 28px;
        line-height: 42px;
        color: #3F434A;
        margin-top: 55px;
        margin-bottom: 36px;
        text-transform: capitalize;
        margin-left: auto;
        text-align: center;
    }
    .item-form{
        max-width: 850px;
        border: 1px solid rgba(0,0,0,50%);
        border-radius: 28px;
        margin: 0 auto;        

        .form-header{
            padding: 30px 30px 0px 30px;
            border-bottom: 1px solid #E8E9EB;

            p{
                font-family: Poppins;
                font-style: normal;
                font-weight: 500;
                font-size: 14px;
                line-height: 21px;
                display: flex;
                align-items: center;
                text-transform: uppercase;
                color: #304FFD;
                margin-bottom: 0;
                border-bottom: 2px solid #304FFD;
                display: inline-block;
                padding: 0px 5px 18px;
            }
        }
        .form-content{
            padding: 20px 30px;

            .scan-item{
                display: flex;

                p{
                    margin-bottom: 0;
                    text-transform: capitalize;
                    font-family: Poppins;
                    font-style: normal;
                    font-weight: 500;
                    font-size: 28px;
                    line-height: 42px;
                    display: flex;
                    align-items: center;
                    color: #3F434A;
                    margin-right: 25px;
                }                
            }
            .scanbtn{
                background-color: #304FFD;
                color: #fff;
                font-family: Poppins;
                font-style: normal;
                font-weight: 500;
                font-size: 15px;
                line-height: 22px;
                text-transform: capitalize;
                border-radius: 14px;
            }
            .mb-3{margin-bottom: 30px !important;}
            label{
                font-family: Poppins;
                font-style: normal;
                font-weight: normal;
                font-size: 14px;
                line-height: 21px;
                color: #8A9099;
                margin-bottom: 8px;
            }
            .form-control {
                border-radius: 14px;
                border-color: #E8E9EB;
                font-family: Poppins;
                font-style: normal;
                font-weight: normal;
                font-size: 14px;
                line-height: 21px;
                color: #3F434A;


                &::placeholder{
                    font-family: Poppins;
                    font-style: normal;
                    font-weight: normal;
                    font-size: 14px;
                    line-height: 30px;
                    color: #3F434A;
                }
                &:focus{
                    box-shadow: none;
                }                
            }
            .form-select{
                border-radius: 14px;
                border-color: #E8E9EB;
                font-family: Poppins;
                font-style: normal;
                font-weight: normal;
                font-size: 14px;
                line-height: 30px;
                color: #3F434A;

                &:focus{
                    box-shadow: none;
                } 
            }
            .text-right{text-align: right;}
        }
        
    }
}

.modal-dialog.ItemSold_width.modal-lg {
    max-width: 1000px;
    .modal-content {
        border-radius: 28px !important;
        border: 0px none !important;
    }
    .modal-header {
        background-color: #FFE8C6;
        border-radius: 28px 28px 0px 0px;
        padding: 15px 50px;
    }
    .modal-title{
        font-family: Roboto;
        font-style: normal;
        font-weight: bold;
        font-size: 32px;
        line-height: 24px;
        color: #815F2F; 
    }
    .modal-body {
        padding: 30px 80px 80px 80px;
        .productImage {
            text-align: center;
            width: 300px;

            img{
                width: 100%;
            }
        }
        .productdetail_mainclass {
            display: flex;
            align-items: flex-end;
            .product_details{
                h2 {
                    font-family: Poppins;
                    font-weight: 500;
                    font-size: 25px;
                    line-height: 37px;
                }
                p {
                    font-weight: normal;
                    font-size: 14px;
                    line-height: 21px;
                    display: flex;
                    align-items: center;
                    color: #595F69;
                    font-family: Poppins;
                    font-style: normal;
                    margin-bottom: 50px;
                }
                p.product_UID {
                    font-family: Poppins;
                    font-style: normal;
                    font-weight: normal;
                    font-size: 14px;
                    line-height: 21px;                                     
                    color: #8A9099;                    
                    opacity: 0.87;
                    margin-bottom: 16px;
                }
                .information_productdetails {
                    p {
                        font-family: Poppins;
                        font-size: 16px;
                        line-height: 22px;
                        display: flex;
                        color: #f52525;
                    }
                    .text_info {
                        border-bottom: 1px solid #ccc;
                        padding-bottom: 10px;
                        margin-bottom: 10px;
                        font-family: Poppins;
                    }
                    .text_info1{
                        margin-bottom: 13px;
                    }
                }
                .Passcode_form {
                    position: relative;
                    .mb-3 {
                        width: calc(100% - 130px);
                        display: inline-block;
                        vertical-align: top;
                        .form-label {
                            color: #8A9099;
                            font-size: 16px;
                        }
                        input {
                            border-radius: 14px;
                            color: #000;
                            font-size: 16px;
                            height: 40px;
                        }
                    }
                    .btn-managment {
                        background: #304FFD;
                        font-family: Poppins;
                        font-weight: 500;
                        font-size: 15px;
                        line-height: 22px;
                        color: #FFFFFF;
                        border-radius: 14px;
                        min-width: 120px;
                        margin-left: 10px;
                        height: 40px;
                        position: relative;
                        top: 38px;
                    }
                }
            }
        }
        
    }    
}
.scanner_popup{
    position: absolute; 
    top: 20%; 
    left: 50%;
    transform: translate(-50%, -20%);

    video{
        width: 500px;
        height: initial !important;
        border-radius: 28px;
    }
    @media screen and (min-width:1200px) and (max-width:1400px){
        left: 15%;
    }
}
.item_alert{position: relative;}
.item_alert .modal-offtext{position: absolute; left: 50%; top: -33px; color: #fff; transform: translate(-50%, 0%); font-family: Roboto;}
.item_alert .modal-header{background-color: orange !important;}
.item_alert .modal-title{color: #fff !important;}
.item_alert .modal-body .row{align-items: center !important;}
.item_alert .btn-modal{       
    color: #fff;
    padding: 9px 16px;
    font-family: Roboto;
    font-style: normal;
    border-radius: 14px;
    font-weight: 500;
    font-size: 16px;
    line-height: 18px;
    padding: 9px 110px;
    margin-top: 20px;
    margin-bottom: 30px;

    &:disabled{
        background-color: #D8D8D8;
        border: none;
    }
}
.item_alert .processing{
    position: absolute;
    right: 20px;
    bottom: 10px;
    font-family: Roboto;
    font-size: 12px;
    color: #000;
    text-decoration: none;
    border: 1px solid #e6e6e6;
    padding: 5px 10px;
    border-radius: 14px;

    &:hover{
        background-color: #304FFD;
        color: #fff;
    }
}

